<i18n>
{
  "de": {
    "reidaFilterTitle": "Report nach REIDA",
    "type": {
      "NOT_CONSIDERED": "Nicht anwendbar",
      "NOT_EXISTING": "Nicht-Bestandesliegenschaft",
      "EXISTING_NOT_CONFORM": "Bestandesliegenschaft (fehlende Messdaten)",
      "EXISTING_AND_CONFORM": "Bestandesliegenschaft (genügend Messdaten)"
    }
  }
}
</i18n>

<template>
  <Menu
    :left="left"
    stayOpen
    class="c-reida-dropdown"
    :hasSelection="Boolean(selectedYear)"
    :buttonText="buttonText"
    @reset="resetSelection"
  >
    <template #header>
      <MenuHeader :title="$t('reidaFilterTitle')" hideSelectAll :disableReset="!selectedYear" @reset="resetSelection" />
    </template>
    <template #options>
      <MenuItem
        v-for="value in ['EXISTING_AND_CONFORM', 'EXISTING_NOT_CONFORM', 'NOT_EXISTING', 'NOT_CONSIDERED']"
        :id="`reida-type-${value}`"
        :key="value"
        :label="$t(`type.${value}`)"
        :type="'checkbox'"
        :value="value"
        :checked="selectedTypes.includes(value)"
        :disabled="!Boolean(selectedYear)"
        @change="onTypeSelect"
      />
      <li class="separator"></li>
      <MenuItem
        v-for="value in allYears"
        :id="`reida-year-${value}`"
        :key="`reida-year-${value}`"
        :label="value.toString()"
        :type="'radio'"
        :value="value"
        :checked="selectedYear === value"
        @change="onYearSelect"
      />
    </template>
  </Menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Menu from '@/components/shared/menu/Menu.vue'
import MenuHeader from '@/components/shared/menu/MenuHeader.vue'
import MenuItem from '@/components/shared/menu/MenuItem.vue'

export default {
  components: {
    Menu,
    MenuHeader,
    MenuItem,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedYear: null,
      selectedTypes: ['EXISTING_AND_CONFORM'],
    }
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),

    allYears() {
      const start = this.portfolio.sim_start_year
      const end = Math.min(this.portfolio.sim_end_year, new Date().getFullYear())
      return [...Array(end - start).keys()].map((v) => v + start)
    },

    buttonText() {
      return this.$t('reidaFilterTitle') + (this.selectedYear ? `: ${this.selectedYear}` : '')
    },
  },

  watch: {
    query() {
      this.updateFilters()
    },
  },

  mounted() {
    this.updateFilters()
  },

  methods: {
    ...mapActions({
      setReidaYear: 'query/setReidaYear',
      toggleReidaType: 'query/toggleReidaType',
    }),

    onYearSelect(year) {
      this.setReidaYear({ year })
      this.updateFilters()
    },

    onTypeSelect(type) {
      this.toggleReidaType({ type })
      this.updateFilters()
    },

    resetSelection() {
      this.setReidaYear({ year: null })
      this.updateFilters()
    },

    updateFilters() {
      this.selectedYear = this.query.reida.year
      this.selectedTypes = this.query.reida.types
    },
  },
}
</script>

<style scoped>
.separator {
  height: 2px;
  padding: 0;
  background-color: var(--separator-color);
}
</style>
