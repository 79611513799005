<!--
Required: portfolio_id
Provides: portfolioBuildingTags
-->
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getPortfolioBuildingTags: 'portfolioBuildingTags/getPortfolioBuildingTags',
      getPortfolioBuildingTagsLoading: 'portfolioBuildingTags/getPortfolioBuildingTagsLoading',
      getPortfolioBuildingTagsLoaded: 'portfolioBuildingTags/getPortfolioBuildingTagsLoaded',
    }),

    portfolioBuildingTags() {
      return this.getPortfolioBuildingTags()
    },

    portfolioBuildingTagsLoading() {
      return this.getPortfolioBuildingTagsLoading()
    },

    portfolioBuildingTagsLoaded() {
      return this.getPortfolioBuildingTagsLoaded()
    },
  },

  watch: {
    portfolio_id() {
      this.loadPortfolioBuildingTags({ portfolioId: this.portfolio_id })
    },
  },

  created() {
    this.loadPortfolioBuildingTags({ portfolioId: this.portfolio_id })
  },

  methods: {
    ...mapActions({
      loadPortfolioBuildingTags: 'portfolioBuildingTags/load',
    }),
  },
}
</script>
