<template>
  <button type="button" :class="classes" :disabled="disabled" @click="onClick">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    classNames: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        button: true,
        [this.classNames]: true,
      }
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
