<!--
Dropdown filtering menu to select a number of Tags in one TagGroup. When dropdown is closed, it shows which Tags are selected
-->
<i18n>
{
  "de": {
    "unassignedBuildings": "Nicht zugewiesene Gebäude",
    "unknown": "unbekannt"
  }
}
</i18n>

<template>
  <Menu
    :left="left"
    stay-open
    class="c-tag-group-dropdown"
    :has-selection="Boolean(selectedTagIds.length)"
    :button-text="buttonText(tagGroup)"
    @reset="resetSelection"
  >
    <template #header>
      <MenuHeader
        :title="tagGroup.auto ? $t(`_tagGroupNames.${tagGroup.name}`) : tagGroup.name"
        :disable-select-all="Boolean(selectedTagIds.length && selectedTagIds.length === tags.length)"
        :disable-reset="!selectedTagIds.length"
        @select-all="selectAll"
        @reset="resetSelection"
      />
    </template>
    <template #options>
      <MenuItem
        v-for="tag in tags"
        :id="inputId(tag.id)"
        :key="tag.id"
        :label="buildingTagLabel(tagGroup, tag.name, tag.count)"
        :type="'checkbox'"
        :value="tag.id"
        :checked="selectedTagIds.includes(tag.id)"
        @change="onTagSelect"
      />
    </template>
  </Menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Menu from '@/components/shared/menu/Menu.vue'
import MenuHeader from '@/components/shared/menu/MenuHeader.vue'
import MenuItem from '@/components/shared/menu/MenuItem.vue'

export default {
  components: {
    Menu,
    MenuHeader,
    MenuItem,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    tagGroup: {
      type: Object,
      required: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedTagIds: [],
    }
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),

    unassignedTagsCount() {
      return this.tagGroup.total_available - this.tagGroup.tags.reduce((acc, tag) => acc + tag.count, 0)
    },

    // Only available tags
    tags() {
      const hasUnassigned = this.unassignedTagsCount > 0

      const tags = this.tagGroup.tags
        .filter((tag) => tag.count > 0)
        .map((tag) => ({
          ...tag,
          localizedName: this.buildingTagLabel(this.tagGroup, tag.name),
        }))
        .sort((a, b) => a.localizedName.localeCompare(b.localizedName))

      return [
        // Only tags with buildingCount > 0
        ...tags,
        // If has unassigned tags
        ...(hasUnassigned ? [{ id: 0, name: 'unassigned', count: this.unassignedTagsCount }] : []),
      ]
    },
  },

  watch: {
    query() {
      this.updateFilters()
    },
  },

  mounted() {
    this.updateFilters()
  },

  methods: {
    ...mapActions({
      setTagFilters: 'query/setTagFilters',
    }),

    inputId(tagId) {
      // Input id unique for instance of component and tag
      return `tag-${this.tagGroup.id}-${tagId}`
    },

    buttonText(tagGroup) {
      const selectedTagNames = this.selectedTagIds.length
        ? this.tags
            .filter((tag) => this.selectedTagIds.includes(tag.id))
            .map((tag) =>
              tag.name === 'unassigned' ? this.$t('unassignedBuildings') : this.buildingTagLabel(tagGroup, tag.name)
            )
            .join(', ')
        : ''
      const tagGroupName = tagGroup.auto ? this.$t(`_tagGroupNames.${tagGroup.name}`) : tagGroup.name

      return tagGroupName + (selectedTagNames ? `: ${selectedTagNames}` : '')
    },

    buildingTagLabel(tagGroup, tagName) {
      if (tagName === 'unassigned') return this.$t('unassignedBuildings')

      if (tagGroup.auto) {
        if (tagGroup.name === 'heating') {
          if (tagName === 'NONE') return this.$t('_buildingTags.heating.NONE')

          const index = this.portfolio.heating_types.findIndex((h) => h.name === tagName)
          return this.portfolio.heating_types[index].default
            ? this.$t(`_buildingTags.heating.${tagName}`)
            : this.portfolio.heating_types[index].name
        }

        const translationKey = `_buildingTags.${tagGroup.name}.${tagName}`
        const translation = this.$t(translationKey)

        return translation
      } else return tagName // If !tagGroup.auto return raw tag name without translation
    },

    onTagSelect(tagId, selected) {
      const selectedTagIds = this.selectedTagIds
      const index = selectedTagIds.indexOf(tagId)

      // Update selectedTagIds
      if (tagId !== undefined && selected) selectedTagIds.push(tagId)
      else if (index !== -1) selectedTagIds.splice(index, 1)

      this.setTagFilters({
        payload: {
          tagGroupId: this.tagGroup.id,
          tagIdFilters: selectedTagIds,
        },
        portfolioId: this.portfolio.id,
      })
    },

    updateFilters() {
      this.selectedTagIds = []
      if (this.query.filters[this.tagGroup.id] !== undefined) {
        this.query.filters[this.tagGroup.id].forEach((value) => {
          this.selectedTagIds.push(value)
        })
      }
    },

    selectAll() {
      if (this.selectedTagIds.length !== this.tags.length) {
        this.selectedTagIds = this.tags.map((t) => t.id)
        this.onTagSelect()
      }
    },

    resetSelection() {
      if (this.selectedTagIds.length) {
        this.selectedTagIds = []
        this.onTagSelect()
      }
    },
  },
}
</script>
