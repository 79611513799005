<template>
  <img :class="`cui-icon ${className}`" :src="iconSrc" />
</template>

<script>
export default {
  name: 'Icon',

  props: {
    className: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
  },

  computed: {
    iconSrc() {
      // If full path with extension used
      if (/\.[a-zA-Z0-9]+$/.test(this.icon)) return this.icon
      // If short name used
      else return `/icons/${this.icon}.svg`
    },
  },
}
</script>
