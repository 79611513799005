<i18n>
{
  "de": {
    "statusFilterTitle": "Rolle",
    "infoBoxTitle": "Rolle",
    "infoBoxText": "Archivierte Liegenschaften werden standardmässig nicht geladen. Wählen Sie diese hier aus, um sie anzuzeigen.",
    "types": {
      "PLANNER": "Planer",
      "REPORTER": "Reporter",
      "ARCHIVED": "Archiviert"
    }
  }
}
</i18n>
<template>
  <Menu
    :left="left"
    stayOpen
    class="c-status-dropdown"
    :hasSelection="Boolean(selectedTypes.length)"
    :buttonText="buttonText"
    @reset="resetSelection"
  >
    <template #header>
      <MenuHeader
        :title="$t('statusFilterTitle')"
        :disable-select-all="Boolean(selectedTypes.length && selectedTypes.length === 3)"
        :disable-reset="!selectedTypes.length"
        :info-box-text="$t('infoBoxText')"
        @select-all="selectAll"
        @reset="resetSelection"
      />
    </template>
    <template #options>
      <MenuItem
        v-for="t in ['PLANNER', 'REPORTER', 'ARCHIVED']"
        :id="`status-type-${t}`"
        :key="t"
        :label="$t(`types.${t}`)"
        :type="'checkbox'"
        :value="t"
        :checked="selectedTypes.includes(t)"
        @change="onTypeSelect"
      />
    </template>
  </Menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Menu from '@/components/shared/menu/Menu.vue'
import MenuHeader from '@/components/shared/menu/MenuHeader.vue'
import MenuItem from '@/components/shared/menu/MenuItem.vue'

export default {
  components: {
    Menu,
    MenuHeader,
    MenuItem,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    left: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      selectedTypes: [],
    }
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),

    buttonText() {
      return this.$t('statusFilterTitle') + (this.selectedTypes.length
        ? ': ' + this.selectedTypes
          .map((type) => this.$t(`types.${type}`))
          .join(', ')
        : '')
    },
  },

  watch: {
    query() {
      this.updateFilters()
    }
  },

  mounted() {
    this.updateFilters()
  },

  methods: {
    ...mapActions({
      setStatusFilter: 'query/setStatusFilter',
    }),

    onTypeSelect(type) {
      const selectedTypes = this.selectedTypes
      const index = selectedTypes.indexOf(type)

      if (type !== undefined) {
        if (index === -1) selectedTypes.push(type)
        else selectedTypes.splice(index, 1)
      }

      this.setStatusFilter({
        selectedTypes: selectedTypes,
      })
    }, 

    updateFilters() {
      this.selectedTypes =  []
      if (this.query.status !== undefined) {
        this.query.status.forEach((value) => {
          this.selectedTypes.push(value)
        })
      }
    },

    selectAll() {
      if (this.selectedTypes.length !== 3) {
        this.selectedTypes = ['PLANNER', 'REPORTER', 'ARCHIVED']
        this.setStatusFilter({
          selectedTypes: this.selectedTypes
        })
      }
    },

    resetSelection() {
      if (this.selectedTypes.length) {
        this.selectedTypes = []
        this.setStatusFilter({
          selectedTypes: this.selectedTypes
        })
      }
    },
  }
}
</script>
