import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=662d7846&scoped=true&"
import script from "./Button.vue?vue&type=script&lang=js&"
export * from "./Button.vue?vue&type=script&lang=js&"
import style0 from "./Button.vue?vue&type=style&index=0&id=662d7846&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662d7846",
  null
  
)

export default component.exports