<i18n>
  {
    "de": {
      "selectAll": "Alle Auswählen",
      "reset": "Zurücksetzen"
    }
  }
</i18n>

<template>
  <div class="c-menu-header">
    <span class="title">
      {{ title }} <InfoBox v-if="infoBoxText !== undefined" :title="infoBoxTitle">{{ infoBoxText }}</InfoBox>
    </span>
    <div class="action">
      <span v-if="!hideSelectAll" :class="{ disabled: disableSelectAll }" @click.stop="selectAll">{{
        $t('selectAll')
      }}</span>
      <span v-if="!hideReset" :class="{ disabled: disableReset }" @click.stop="reset">{{ $t('reset') }}</span>
    </div>
  </div>
</template>

<script>
import InfoBox from '@/components/shared/InfoBox.vue'

export default {
  components: {
    InfoBox,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    disableSelectAll: {
      type: Boolean,
      default: false,
    },
    disableReset: {
      type: Boolean,
      default: false,
    },
    hideSelectAll: {
      type: Boolean,
      default: false,
    },
    hideReset: {
      type: Boolean,
      default: false,
    },

    infoBoxText: {
      type: String,
      required: false,
    },

    infoBoxTitle: {
      type: String,
      required: false,
    },
  },

  methods: {
    selectAll() {
      this.$emit('select-all')
    },
    reset() {
      this.$emit('reset')
    },
  },
}
</script>

<style lang="scss" scoped>
.c-menu-header {
  padding: var(--spacing-s);
  padding-right: var(--spacing-m);
  border-bottom: var(--box-border);

  & .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: var(--spacing-xs);
  }

  & .action {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-m);
  }

  & .disabled {
    cursor: not-allowed;
    color: #aaa;
  }
}
</style>
