<template>
  <span class="c-info-box">
    <VPopover trigger="hover" placement="top">
      <img src="/icons/info-circle.svg" alt="info" />
      <template slot="popover">
        <header v-if="title">{{ title }}</header>
        <main>
          <slot></slot>
        </main>
      </template>
    </VPopover>
  </span>
</template>

<script>
import { VPopover } from 'v-tooltip'

export default {
  components: {
    VPopover,
  },

  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.c-info-box {
  flex: none;
  display: inline-block;
  margin: 2px 4px 2px 4px;
  width: 16px;
  height: 16px;
  line-height: 16px;
}
</style>

<style lang="scss">
.c-info-box {
  & .v-popover {
    height: 100%;

    & .trigger {
      height: 100% !important;
    }
  }
}
</style>
